@import "variables";

body {
  background-color: $col_main;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

//Navbar
.logo {
  width: 100px;
}

.navbar-light .navbar-nav .nav-link {
  color: whitesmoke;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: whitesmoke;
}

.navbar-toggler {
  background-color: $col_text;
}

.btn {
  color: $col_text;
  border: 1px solid $col_text;
}

.btn:hover {
  color: $col_hover;
  border: 1px solid $col_hover;
}

//Footer
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $col_main;
  color: $col_hover;
  text-align: center;
}

//Home
.newsBubble {
  background-color: $col_text;
  border-radius: 10px;
  width: 92.5%;
}

//Server Software
.ServerBox {
  border: 1px solid $col_white;
  border-radius: 10px;
  color: $col_text;
}

.ServerBox button {
  border: 1px solid $col_white;
  border-radius: 10px;
  font-size: $txt-medium;
  cursor: pointer;
  background-color: $col_black;
  color: $col_text;
}

.ServerBox button:hover {
  color: $col_hover;
  border: 1px solid $col_hover;
}

.ServerBoxImg {
  border-radius: 10px;
  width: 100%;
}

.SubnaticaImg {
  height: 50%;
}

//Server List

//About US
.DropdownAccordion {
  width: 92.5%;
}

//Contact Us