// Fonts
$font-main: "Merriweather", serif; // DEPRECATED: use index.css instead for fonts
$font-highlight: "Bree Serif", serif;

//Text sizes
$txt-small: 1.2rem;
$txt-medium: 1.4rem;
$txt-large: 1.6rem;
$txt-xlarge: 1.8rem;
$txt-xxlarge: 2rem;


// Colors
$col_white: #f5f5f5;
$col_black: #1d1b1b;
$col_cyan: #00ffff;

// Color control
$col_main: $col_black;
$col_text: $col_white;
$col_hover: $col_cyan;